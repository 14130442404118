import React, {useEffect, useState} from 'react';

const KakaoMap = () => {
    const [lat, setLat] = useState(33.450701);  // 초기값 설정
    const [lon, setLon] = useState(126.570667); // 초기값 설정

    // 여러 개의 마커 위치를 담은 배열
    const markers = [
        { lat: lat+0.3, lon: lon },
        { lat: lat+0.6, lon: lon },
        { lat: lat+0.9, lon: lon },
        { lat: lat+1.2, lon: lon },
    ];

    useEffect(() => {
        const loadMap = () => {
            if (!window.kakao || !window.kakao.maps) return;

            window.kakao.maps.load(() => {
                const container = document.getElementById('kakaoMap');
                const options = {
                    center: new window.kakao.maps.LatLng(lat, lon),
                    level: 3,
                };

                const map = new window.kakao.maps.Map(container, options); // 지도 생성

                // 각 마커 위치를 순회하며 마커 생성
                markers.forEach(markerPosition => {
                    const position = new window.kakao.maps.LatLng(markerPosition.lat, markerPosition.lon);
                    const marker = new window.kakao.maps.Marker({
                        position,
                    });
                    marker.setMap(map); // 각 마커를 지도에 표시
                });
            });
        };

        const loadScript = () => {
            return new Promise((resolve) => {
                if (!window.kakao) {
                    const script = document.createElement('script');
                    script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_MAP_KEY}&autoload=false`;
                    script.async = true;
                    script.onload = resolve;
                    document.head.appendChild(script);
                } else {
                    resolve();
                }
            });
        };

        const initMap = async () => {
            // 위치 정보 가져오기
            navigator.geolocation.getCurrentPosition((position) => {
                setLat(position.coords.latitude);
                setLon(position.coords.longitude);
            });

            await loadScript();
            loadMap(); // Kakao Maps API 초기화 후 지도 생성
        };

        initMap(); // 비동기 초기화 함수 실행
    }, [lat, lon]);

    return (
        <div
            id="kakaoMap"
            style={{
                width: '100%',
                height: '400px',
            }}
        ></div>
    );
};

export default KakaoMap;