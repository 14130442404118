import "../../styles/signature/sigCanvas.css";
import {IconButton, Modal} from "@mui/material";
import {useEffect, useState} from "react";
import {useRecoilState} from "recoil";
import {secretMapOpenFlag} from "../../atom/secretMap/secretMapOpenFlag";
import SoftBox from "../../components/SoftBox";
import {Card} from "react-bootstrap";
import Grid from "@mui/material/Grid";
import SoftTypography from "../../components/SoftTypography";
import {Close} from "@mui/icons-material";
import KakaoMap from "./components/kakaoMap";
import KakaoMapWithDetails from "./components/KakaoMapWithDetails";


function SecretMap({mapOpen}) {

    // 맛집 지도 모달 오픈
    const [secretMapOpen, setSecretMapOpen] = useRecoilState(secretMapOpenFlag);


    return (
        <>
            <Modal
                open={secretMapOpen}
                onClose={() => setSecretMapOpen(false)}
                BackdropProps={{
                    onClick: (event) => event.stopPropagation() // 백드롭 클릭 이벤트를 막음
                }}
            >
                <SoftBox m={2} p={2} bgColor={"white"}
                         borderRadius={2} height={"95vh"}
                         sx={{
                             overflowY: "scroll", // 수직 스크롤만 활성화
                             '&::-webkit-scrollbar': { // 웹킷 기반 브라우저의 스크롤바 숨김
                                 width: '0px', // 스크롤바 너비를 0으로 설정
                             },
                             '&::-webkit-scrollbar-thumb': {
                                 backgroundColor: 'transparent', // 스크롤바 thumb를 투명하게 설정
                             },
                        }}
                >
                    <Grid container display="flex" justifyContent="space-between" alignItems="center">
                        <Grid item xs={10} display="flex" justifyContent="flex-start">
                            <SoftTypography variant="h4" color="black" fontWeight="bold">
                                맛집 지도
                            </SoftTypography>
                        </Grid>
                        <Grid item xs={2} display="flex" justifyContent="flex-end">
                            <IconButton
                                onClick={() => setSecretMapOpen(false)}
                                aria-label="close"
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'lightgray', // 호버 시 배경색 설정
                                    },
                                }}
                            >
                                <Close />
                            </IconButton>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="center">
                            <KakaoMapWithDetails/>
                        </Grid>
                        <Grid item xs={12} display="flex" justifyContent="center">

                        </Grid>
                    </Grid>
                </SoftBox>
            </Modal>
        </>
    )
        ;
}

export default SecretMap;
