// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.example-custom-input{
    background-color: rgb(0, 157, 255);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    font-size: medium;
    font-weight: 700;
    width : max-content
}

.Filename {
    font-size: small;
    width: max-content;
}`, "",{"version":3,"sources":["webpack://./src/layouts/wpEdit/components/index.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,YAAY;IACZ,YAAY;IACZ,oBAAoB;IACpB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB;AACJ;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB","sourcesContent":[".example-custom-input{\n    background-color: rgb(0, 157, 255);\n    color: white;\n    border: none;\n    padding: 0.5rem 1rem;\n    border-radius: 5px;\n    cursor: pointer;\n    font-size: medium;\n    font-weight: 700;\n    width : max-content\n}\n\n.Filename {\n    font-size: small;\n    width: max-content;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
