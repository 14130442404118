// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker__input-container input {
    height: 29.99px;
    text-align: center;
    width : 80px;
    margin-top : 5px;
  }

.modBtn {
    margin-top : 10px;
}`, "",{"version":3,"sources":["webpack://./src/layouts/admin/member/index.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;EAClB;;AAEF;IACI,iBAAiB;AACrB","sourcesContent":[".react-datepicker__input-container input {\n    height: 29.99px;\n    text-align: center;\n    width : 80px;\n    margin-top : 5px;\n  }\n\n.modBtn {\n    margin-top : 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
