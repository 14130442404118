// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sigCanvas {
    border: 2px solid black;
    border-radius: 10px;
    width: 300px;
    height: 150px;
}`, "",{"version":3,"sources":["webpack://./src/styles/signature/sigCanvas.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,aAAa;AACjB","sourcesContent":[".sigCanvas {\n    border: 2px solid black;\n    border-radius: 10px;\n    width: 300px;\n    height: 150px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
