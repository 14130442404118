import React, {useEffect, useState} from 'react';

const KakaoMapWithDetails = () => {
    const [lat, setLat] = useState(33.450701);  // 초기 위치
    const [lon, setLon] = useState(126.570667); // 초기 위치
    const [keyword, setKeyword] = useState("카페"); // 검색할 키워드
    const [search, setSearch] = useState("카페"); // 검색할 키워드
    const [placeDetails, setPlaceDetails] = useState(null); // 가게 정보 상태

    useEffect(() => {
        const loadMap = () => {
            if (!window.kakao || !window.kakao.maps) return;

            window.kakao.maps.load(() => {
                const container = document.getElementById('kakaoMap');
                const options = {
                    center: new window.kakao.maps.LatLng(lat, lon),
                    level: 3,
                };

                const map = new window.kakao.maps.Map(container, options);

                // 장소 검색 객체 생성
                const ps = new window.kakao.maps.services.Places();

                // 장소 검색을 실행
                ps.keywordSearch(keyword, (data, status) => {
                    if (status === window.kakao.maps.services.Status.OK) {
                        const firstPlace = data[0]; // 첫 번째 검색 결과
                        const newLat = firstPlace.y;
                        const newLon = firstPlace.x;

                        // 검색 결과 위치로 지도 이동
                        const moveLatLon = new window.kakao.maps.LatLng(newLat, newLon);
                        map.setCenter(moveLatLon);

                        // 마커 생성
                        const marker = new window.kakao.maps.Marker({
                            position: moveLatLon,
                        });
                        marker.setMap(map); // 마커 지도에 표시

                        // 가게 정보 저장
                        setPlaceDetails({
                            name: firstPlace.place_name,
                            address: firstPlace.road_address_name || firstPlace.address_name,
                            phone: firstPlace.phone || "전화번호 없음",
                            latitude: newLat,
                            longitude: newLon,
                        });

                        setLat(newLat);
                        setLon(newLon);
                    } else {
                        console.error("검색 결과가 없습니다.");
                    }
                });
            });
        };

        const loadScript = () => {
            return new Promise((resolve) => {
                if (!window.kakao) {
                    const script = document.createElement('script');
                    script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=${process.env.REACT_APP_KAKAO_MAP_KEY}&libraries=services&autoload=false`;
                    script.async = true;
                    script.onload = resolve;
                    document.head.appendChild(script);
                } else {
                    resolve();
                }
            });
        };

        const initMap = async () => {
            await loadScript();
            loadMap();
        };

        initMap();
    }, [lat, lon, keyword]); // 키워드가 변경될 때마다 다시 검색

    const handleKeyDownEnter = (event) => {
        if (event.key === 'Enter') {
            setKeyword(search);
            event.preventDefault(); // 기본 엔터 동작 방지 (줄 바꿈 방지)
        }
    };

    return (
        <div
            style={{
                width: '100%',
                height: '400px',
            }}
        >
            <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onBlur={()=> setKeyword(search)}
                onKeyDown={handleKeyDownEnter}
                placeholder="검색할 장소를 입력하세요"
                style={{
                    width: '100%'
                }}
            />
            <div
                id="kakaoMap"
                style={{
                    width: '100%',
                    height: '100%',
                }}
            ></div>
            {placeDetails && (
                <div style={{ marginTop: '10px' }}>
                    <h3>{placeDetails.name}</h3>
                    <p>주소: {placeDetails.address}</p>
                    <p>전화번호: {placeDetails.phone}</p>
                    <p>위도: {placeDetails.latitude}</p>
                    <p>경도: {placeDetails.longitude}</p>
                </div>
            )}
        </div>
    );
};

export default KakaoMapWithDetails;